<template>
    <footer class="p-4 pt-12 text-white sm:p-6 md:pt-28 lg:pt-36">
        <div class="container">
            <div class="md:flex md:justify-between">
                <div class="mb-6 md:mb-0">
                    <a href="https://luke.consulting" class="flex items-center">
                        <img
                            src="/logo.png"
                            class="mr-3 h-8 invert hover:scale-105"
                            alt="Luke AI Consulting Logo"
                        />
                        <span
                            class="hidden self-center whitespace-nowrap text-2xl font-semibold"
                        >
                            Luke Consulting
                        </span>
                    </a>
                </div>
                <div class="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6">
                    <!-- Resources -->
                    <div v-if="0">
                        <h2
                            class="mb-6 text-sm font-semibold uppercase text-white"
                        >
                            Resources
                        </h2>
                        <ul class="text-gray-400">
                            <li class="mb-4">
                                <a
                                    href="https://Luke AI Consulting.com"
                                    class="hover:underline"
                                >
                                    Luke AI Consulting
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://tailwindcss.com/"
                                    class="hover:underline"
                                >
                                    Tailwind CSS
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- Follow Us -->
                    <div v-if="0">
                        <h2
                            class="mb-6 text-sm font-semibold uppercase text-gray-900 text-white"
                        >
                            Follow us
                        </h2>
                        <ul class="text-gray-400 text-gray-600">
                            <li class="mb-4">
                                <a
                                    href="https://github.com/themesberg/Luke AI Consulting"
                                    class="hover:underline"
                                >
                                    Github
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://discord.gg/4eeurUVvTy"
                                    class="hover:underline"
                                >
                                    Discord
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- Legal -->
                    <div v-if="0">
                        <h2 class="mb-6 text-sm font-semibold uppercase">
                            Legal
                        </h2>
                        <ul class="text-gray-200">
                            <li class="mb-4">
                                <a
                                    href="https://thetext.app/privacy-policy"
                                    target="_blank"
                                    ref="nofollow"
                                    class="hover:underline"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://thetext.app/terms-of-service"
                                    target="_blank"
                                    ref="nofollow"
                                    class="hover:underline"
                                >
                                    Terms &amp; Conditions
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr class="my-6 border-gray-300 sm:mx-auto lg:my-8" />

            <div
                class="flex flex-col gap-2 text-sm text-gray-200 md:flex-row md:items-center md:justify-between md:text-center"
            >
                <span>© 2023 Luke Consulting.</span>
                <span>Built with ❤️ in Copenhagen, 🇩🇰.</span>
            </div>
        </div>
    </footer>
</template>
