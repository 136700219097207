<template>
    <div
        class="flex min-h-screen flex-col scroll-smooth bg-gradient-to-b from-slate-900 to-stone-950 text-white"
    >
        <Navigation />
        <!-- NavMenu -->
        <slot />

        <span class="flex flex-1"></span>
        <IndexTheFooterSection />
    </div>
</template>
