<template>
    <header class="navigation sticky left-0 top-0 z-50">
        <nav class="px-2 py-2.5 text-white md:px-4 lg:px-6">
            <div class="container flex flex-wrap items-center justify-between">
                <NuxtLink href="/">
                    <img
                        src="/logo.png"
                        class="mr-3 h-6 invert sm:h-9"
                        alt="The Text App Logo"
                    />
                </NuxtLink>
                <!-- Actions -->
                <div class="z-10 flex items-center lg:order-2">
                    <!-- <a
                        href="https://thetext.app/login"
                        class="mr-2 rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-800 lg:px-5 lg:py-2.5"
                    >
                        Log in
                    </a> -->

                    <NuxtLink href="/#pricing">
                        <GeneralPrimaryButton>Get started</GeneralPrimaryButton>
                    </NuxtLink>

                    <button
                        data-collapse-toggle="mobile-menu"
                        type="button"
                        class="ml-1 inline-flex items-center rounded-lg p-2 text-sm text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 lg:hidden"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                    >
                        <span class="sr-only">Open main menu</span>
                        <svg
                            class="h-6 w-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                        <svg
                            class="hidden h-6 w-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
                <!-- Menu -->
                <div
                    class="z-10 hidden w-full items-center justify-between lg:order-1 lg:flex lg:w-auto"
                    id="mobile-menu"
                >
                    <ul
                        class="my-4 flex flex-col font-medium lg:my-0 lg:flex-row lg:gap-8"
                    >
                        <li>
                            <NuxtLink href="/" class="link">Home</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink href="/#benefits" class="link"
                                >Benefits</NuxtLink
                            >
                        </li>
                        <li>
                            <NuxtLink href="/#pricing" class="link"
                                >Pricing</NuxtLink
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<style scoped>
    .navigation:before {
        height: 100%;
        inset: 0;
        margin: auto;
        background: rgba(0, 0, 0, 0.05);
        opacity: 1;
        backdrop-filter: blur(5px);
    }

    .navigation:after,
    .navigation:before {
        content: '';
        width: 100%;
        position: absolute;
        pointer-events: none;
    }

    .navigation:after {
        height: 1px;
        bottom: 0;
        background: linear-gradient(
            180deg,
            transparent,
            hsla(0, 0%, 100%, 0.3)
        );
        transform-origin: center;
    }

    .link {
        @apply block border-b border-gray-100 py-2 pl-3 pr-4 text-gray-300 hover:text-white lg:border-0 lg:p-0;
    }
</style>
